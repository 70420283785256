<template>
<div class="meo-login">
    <v-overlay class="p-absolute" color="white" opacity="0.8" v-if="loading">
        <v-progress-circular :size="60" :width="6" color="primary" indeterminate></v-progress-circular>
    </v-overlay>
    <v-container px-5 pb-6 v-if="!resetSent && !error" class="dark--text">
        <form @submit.prevent="submit">
            <v-row>
                <v-col class="shrink">
                    <h2 class="text-no-wrap my-6">Reset Password</h2>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12">
                    <v-text-field ref="email" v-model="email" type="email" outlined class="pt-0 mt-0" color="dark" label="Email Address" placeholder="Please enter your email..." required :rules="[rules.required, rules.email]"
                      hint="Enter your email to reset your password" persistent-hint />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block x-large dark color="white" type="submit">
                        <span class="dark--text">Send Reset Link</span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="center" justify="center">
                    <v-btn :to="{name:'auth.login'}" small text color="dark">
                        <span class="text-capitalize font-weight-regular">Log In</span>
                    </v-btn>
                </v-col>
            </v-row>
        </form>
    </v-container>
    <v-container px-5 pb-6 v-else-if="error" class="dark--text">
        <v-row>
            <v-col class="shrink" cols="12">
                <h2 class="text-no-wrap text-center mt-6 mb-3">Reset Password</h2>
                <p class="text-muted text-center dark--text">An error occured while trying to send your password reset request. Please try again.</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-btn block x-large dark color="white" @click="error = false">
                    <span class="dark--text">Send Reset Link</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" align="center" justify="center">
                <v-btn :to="{name:'auth.login'}" small text color="dark">
                    <span class="text-capitalize font-weight-regular">Log In</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
    <v-container px-5 pb-6 v-else class="dark--text">
        <v-row>
            <v-col class="shrink" cols="12">
                <h2 class="text-no-wrap text-center mt-6 mb-3">Reset Password</h2>
                <p class="text-muted text-center dark--text">A password reset email has been sent.</p>
                <p class="text-muted text-center dark--text">It may take a few minutes to recieve this. If you do not recieve this please check that you have entered the correct email address or check your junk mail.</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-btn block x-large color="white" :to="{name:'auth.login'}">
                    Back to Log in
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import _ from 'lodash';
import UserMixin from '@/mixins/user-mixin';

/////////////////////////////////////////////////////////

export default {
    mixins: [UserMixin],
    meta: {
        titleTemplate: "Forgot Password | %s"
    },
    mounted() {
        if (!this.email || !this.email.length) {
            return this.$refs.email.focus()
        }
    },
    data() {
        return {
            loading: false,
            resetSent: false,
            error: false,
            rules: {
                required: value => !!value || 'This field is required',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail'
                },
            }
        }
    },
    methods: {
        submit() {
            this.loading = true;

            //Check if there is an application
            var applicationContext = (this.application && this.application.authenticationStyle == 'application');

            this.$fluro.auth.sendResetPasswordRequest({
                    username: this.email,
                    //This is the route that will receive the token
                    //for the user to reset their password
                    redirect: `/auth/reset`,
                }, {
                    bypassInterceptor: true,
                    application: applicationContext, //Reset from the application
                })
                .then(() => {
                    this.resetSent = true
                    this.loading = false
                }, () => {
                    this.error = true
                    this.loading = false
                });
        }
    },
    computed: {
        // ...mapState(['email']),
        email: {
            set(email) {
                this.$store.commit('email', email);
            },
            get(email) {
                return this.$store.getters.email;
            }
        }
    }
}
</script>
